<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import kitchensTable from './components/kitchensTable';
import { fetchEquipmentsApi } from "@/api/common";
import { required, maxLength } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { getDropDownElementsByTypeApi } from "@/api/common";
import { fetchWarehousesListApi } from "@/api/common";

import equipement from './components/equipement';
// import SideButtons from '../../../../../components/side-buttons.vue';

export default {
  page: {
    title: "Cuisines",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, kitchensTable, Multiselect , equipement /* , SideButtons */},
  data() {
    return {
      reloadkitchens:undefined,
      tableData: [],
      blocs: [],
      equipments: [],
      selectedEquipements : [],
      equipmentStatus: [
        {
          id: 'new',
          name: 'Nouveau'
        },
        {
          id: 'used',
          name: 'Utilisé'
        },
      ],
      kitchenTypes: [],
      title: "Cuisines",
      List_Magasin: [],
      kitchens: [],
      selectedRows: null,
      selectedK: null,
      items: [
        {
          text: "Master Data",
          to: { name: 'master_data' }
        },
        {
          text: "Referentiels",
          to: { name: 'master_data.referentiels' }
        },
        {
          text: "Cuisines",
          active: true
        }
      ],
      /* filter: null,
      filterOn: [], */
     
      fields: [
        { key: "number", sortable: true },
        { key: "bloc", sortable: true },
        { key: "type", sortable: true },
        { key: "status", sortable: true },
      ],
      lifeBases :[],
      lbl :[],
      kitchenForm: {
        id :"",
        lifebase_id: "",
        kitchenName: "",
        kitchenBloc: "",
        kitchenType: "",
        warehouses: "",
      },
      kitchenFormRest: {
        id :"",
        lifebase_id: "",
        kitchenName: "",
        kitchenBloc: "",
        kitchenType: "",
        warehouses: "",
      },
      SelectedCategory: undefined,
      lastSelect      : undefined,
      disabled        : true,
      canDelte        : false,
      disabledAdd     : false,
      disabledUpdate  : false,
      disabledCancel  : false,
      disabledDelete  : true,
      add      : false,
      edit     : false,
      destroy  : false,
      send     : false,
      have     : false,
      dp       : 1,
      submitted: true,
    };
  },
  validations: {
    kitchenForm: {
      kitchenName: { required , maxLength: maxLength(64) },
      kitchenBloc: { required },
      kitchenType: { required },
      lifebase_id: { required },
      warehouses: { required : false }

    },
  },
  computed: {
    
    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    this.lastSelect = this.kitchenForm;
    this.totalRows = this.tableData.length;

    this.getLifeBases();
  //  this.refreshkitchensTable();
  },
  created: function() {
    this.getkitchensTypes();
    this.fetchEquipmentsList();
    this.fetchMagasinList();

  },
  watch :{
    SelectedCategory:{
        handler(val){
            this.kitchenForm.kitchenBloc = val;
        }
    },
    add(){
      this.addBtn()
    },
    duplicate(){
      this.duplicateBtn()
    },
    edit(){
      this.editBtn()
    },
    destroy(){
      this.deleteKitchen()
    },
  },
  methods: {
    
    addBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledEdit   = true;
      this.disabledCancel = false;
      this.disabledDelete = true;
      this.disabledUpdate = false;
      this.clearForms();
    },
    duplicateBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.kitchenForm.uuid  = "";
      this.disabledCancel = true;
      this.disabledDelete = true;
      this.disabledUpdate = false;

    },
    editBtn(){
      this.disabledAdd    = false;
      this.disabledUpdate = true;
      this.disabled       = false;
      this.disabledCancel = false;
      this.disabledDelete = true;

    },
    cancelBtn(){
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledCancel = true;
      this.disabledUpdate = false;
      if(this.lastSelect != null){
        this.kitchenForm = this.lastSelect ;
        this.disabledDelete = false;
        // this.disabledEdit   = false;
      }
    },
    fetchMagasinList(){
      fetchWarehousesListApi()
      .then(res => {
        this.List_Magasin = res.data.data;
        this.refresh=false; 
      })
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },

    getLifeBases(){
      var _this = this
      this.$http.get('/base/lifebases/index')
      .then(response => {
          _this.lbl = response.data;
      })
      .catch(error => console.log(error))
    },
    
    refreshkitchensTable(){
    this.$http.post('/infrastructure/kitchens/list')
          .then(res => (this.kitchens = res.data.original.list))
          .catch(() => {
              // error.response.status Check status code
              
          }).finally(() => {
              //Perform action in always
          });
    },
    /* onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }, */
    
    fetchEquipmentsList() {
      fetchEquipmentsApi()
        .then((res) => (this.equipments = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    getBlocs() {
      var _this = this;
      this.$http
        .post("/infrastructure/blocs/list",{'life_base_id':this.kitchenForm.lifebase_id})
        .then((res) => {
          _this.blocs = res.data.original.list;
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },

    getkitchensTypes() {
      var _this = this;
      getDropDownElementsByTypeApi("kitchen_classification")
        .then((res) => {
          _this.kitchenTypes = res.data.original.list;
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },
    selectedkitchens(selectedkitchens){
        this.clearForms
        this.disabledAdd    = false;
        this.disabledDelete = true;
        this.disabledEdit   = true;
        this.disabledCancel = true;

      if(selectedkitchens.length> 0 &&  undefined != selectedkitchens[0]){
        this.disabled       = true;
        this.disabledAdd    = false;
        this.disabledDelete = false;
        this.disabledEdit   = false;
        this.disabledCancel = true;

        this.kitchenForm.warehouses  = selectedkitchens[0].warehouses;
        this.kitchenForm.id          = selectedkitchens[0].id;
        this.kitchenForm.uuid        = selectedkitchens[0].uuid;
        this.kitchenForm.lifebase_id = selectedkitchens[0].lifebase_id;
        this.getBlocs()
        this.kitchenForm.kitchenName = selectedkitchens[0].name;
        this.SelectedCategory        = selectedkitchens[0].bloc
        this.kitchenForm.kitchenType = selectedkitchens[0].kitchenType;

        this.selectedEquipements = selectedkitchens[0].equipments
        this.selectedK           = selectedkitchens[0];
        this.lastSelect = this.kitchenForm;
      }
    },
    clearForms(){
        this.kitchenForm             = this.kitchenFormRest;
        this.kitchenForm.id          = "";
        this.kitchenForm.lifebase_id = "";
        this.kitchenForm.kitchenName = "";
        this.kitchenForm.kitchenBloc = "";
        this.kitchenForm.kitchenType = "";
        this.kitchenForm.warehouses  = "";
        this.SelectedCategory        = [];
    },

    formSubmit() {
      let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid ) {
        this.submitted = false;
        this.$http
          .post("/infrastructure/kitchens/store", this.kitchenForm)

          .then((res) => {
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                // Swal.fire("Ajouter!", res.data.original.msg, "success");
                this.reloadkitchens = true
                this.disabled       = true;
                this.disabledUpdate = false;
                this.disabledAdd    = false;
                this.edit           = false;
                this.clearForms();
                break;

              case 500:
                this.$toast.error(res.data.original.msg);/* 
                Swal.fire("Erreur!", res.data.original.msg, "error"); */
                break;
            }
            loader.hide();
          })
          .catch((error) => {
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },
    
    deleteKitchen() {
      Swal.fire({
        title: "Êtes-vous sûr de supprimer : " + this.kitchenForm.kitchenName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
          this.$http
            .post("/infrastructure/kitchens/delete/" + this.kitchenForm.uuid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.reloadkitchens = true;
                  this.disabled       = true;
                  this.disabledUpdate = true;
                  this.disabledDelete = true;
                  this.disabledAdd    = false;
                  // this.edit           = true;
                  this.clearForms()
                  break;

                case 500:
                  this.$toast.error(res.data.original.msg);
                  break;
              }
              loader.hide();
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    updateKitchen() {
      let loader = this.$loading.show({
            color: '#000000',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
          })
      this.submitted = true;
      this.$v.$touch();
      if(!this.$v.$invalid){
      this.submitted = false;
      this.$http
        .post("/infrastructure/kitchens/update", this.kitchenForm)
        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
                // Swal.fire("Ajouter!", res.data.original.msg, "success");
              this.reloadkitchens = true
              this.disabled       = true;
              this.disabledUpdate = false;
              this.disabledDelete = false;
              this.disabledAdd    = false;
              this.edit           = false;
              this.clearForms();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
          loader.hide();
        })
        .catch((error) => {
              this.$toast.error(error.message);
        })
        .finally(() => {});
      }
    },
  }
};
</script>
<style >
  .finish-button{
    background-color:#43A047 !important;
    border-color: #43A047 !important;
  }
  #StickyH {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pillH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #8FC412 ;
  }
  .btn-editH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #FFB236 ;
  }
  .btn-deleteH #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-printH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
   .btn-cancelH{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    
    <kitchens-table :reloadkitchens="reloadkitchens" @selectedkitchens="selectedkitchens" @reloadKitchensDone="reloadkitchens = $event" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs content-class="p-3 text-muted" >     
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block" >Infos Générales</span>
                </template>
               <!--  <SideButtons
                      :select="disabledAdd"  :DP="dp" :canEdit="disabledDelete" :save="send" :ODS="false"   @add="add = $event" @duplicate="duplicate = $event" @edit=" edit = $event"  @delete=" destroy = $event" />
                     -->
                <ul id="StickyH">
                  <li>
                    <button type="button" :disabled="disabledAdd && !$can('create_kitchen')" @click="addBtn" class="btn btn-addH rounded-pillH float-right mr-1 mt-1">
                    <i class="fas fa-plus text-white"></i>
                    </button>
                  </li>
                  <li>
                    <button type="button" :disabled="disabledDelete && !$can('update_kitchen')" @click="editBtn" class="btn btn-editH rounded-pillH float-right mr-1 mt-1">
                      <i class="fas fa-pencil-alt text-white" style=""></i>
                    </button>
                  </li>
                  <li>
                    <button type="button" :disabled="disabledDelete && !$can('delete_kitchen') " @click="deleteKitchen" class="btn btn-deleteH rounded-pillH float-right mr-1 mt-1">
                        <i class="far fa-trash-alt text-white"></i>
                    </button>
                  </li>
                </ul>
                <h4 class="card-title  pb-2 col-12">
                  Cuisines Details
                </h4>
                <fieldset :disabled="disabled">
                  <div class="col-12 row">
                    <div class="col-xs-12 col-md  ">
                      <div class="form-group">
                        <label for="validationCustom02">Base De Vie</label>
                        <select @change="getBlocs()"
                          v-model="kitchenForm.lifebase_id"
                          :class="{
                            'is-invalid': submitted && $v.kitchenForm.lifebase_id.$error,
                            'disabledMS': disabled
                          }"
                          class="custom-select"
                        >
                          <option selected disabled>Sélectionner une base </option>
                          <option
                            v-for="lifeBase in lbl"
                            v-bind:value="lifeBase.id"
                            v-bind:key="lifeBase.id"
                          >
                            {{ lifeBase.name }}
                          </option>
                        </select>
                        <div
                          v-if="submitted && $v.kitchenForm.lifebase_id.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.kitchenForm.lifebase_id.required"
                            >Le Base De Vie est obligatoire.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md  ">
                      <div class="form-group">
                        <label for="validationCustom02">Bloc</label>
                        <select
                          v-model="SelectedCategory"
                          :class="{
                            'is-invalid': submitted && $v.kitchenForm.kitchenBloc.$error,
                            'disabledMS': disabled
                          }"
                          class="custom-select"
                        >
                          <option selected>Sélectionner une classe</option>
                          <option
                            v-for="bloc in blocs"
                            v-bind:value="bloc.id"
                            v-bind:key="bloc.id"
                          >
                            {{ bloc.name }}
                          </option>
                        </select>
                        <div
                          v-if="submitted && $v.kitchenForm.kitchenBloc.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.kitchenForm.kitchenBloc.required"
                            >Le bloc est obligatoire.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md  ">
                      <div class="form-group">
                        <label for="validationCustom02">Type</label>
                        <select
                          v-model="kitchenForm.kitchenType"
                          :class="{
                            'is-invalid': submitted && $v.kitchenForm.kitchenType.$error,
                            'disabledMS': disabled
                          }"
                          class="custom-select"
                        >
                          <option selected></option>
                          <option
                            v-for="type in kitchenTypes"
                            v-bind:value="type.id"
                            v-bind:key="type.id"
                          >
                            {{ type.designation }}
                          </option>
                        </select>
                        <div
                          v-if="submitted && $v.kitchenForm.kitchenType.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.kitchenForm.kitchenType.required"
                            >Le type est obligatoire.</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-md  ">
                      <div class="form-group">
                        <label for="deptName">Designation</label>
                        <input
                          id="deptName"
                          v-model="kitchenForm.kitchenName"
                          type="text"
                          class="form-control"
                          placeholder=""
                          value=""
                          :class="{
                            'is-invalid':submitted && $v.kitchenForm.kitchenName.$error,
                            'disabledMS': disabled,
                          }"
                        />
                        <div
                          v-if="submitted && $v.kitchenForm.kitchenName.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.kitchenForm.kitchenName.required">Le numéro de chambre est obligatoire.</span>
                          <span v-if="!$v.kitchenForm.kitchenName.maxLength">La taille du champs est limitée a 64 charactères.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 row">
                    <div class="col-xs-12 col-md col-lg">
                      <div class="form-group">
                        <label for="formrow-email-input">Magasins *</label>
                        <Multiselect
                          v-model="kitchenForm.warehouses"
                          :options="List_Magasin"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          :select-label="''"
                          placeholder="Selectionner une ou plusieurs Magasins"
                          label="name"
                          track-by="id"
                          :preselect-first="true"
                          :class="{'disabledMS': disabled}"
                        />
                        <div
                          v-if="submitted && $v.kitchenForm.warehouses.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.kitchenForm.warehouses.required"
                            >La référence du contrat est obligatoire.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                   <div class="button-items float-right ">
                      <div class="col-md-12" v-show="!disabled"> 
                        <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
                         <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                        </button>
                        
                      <button
                          class="btn btn-primary float-right btn-label"
                          type="button"
                          @click="formSubmit"
                          v-show="disabledAdd && $can('create_kitchen')"
                        >
                          <i class="far fa-save label-icon "></i> Enregistrer
                        </button>
                        <button
                          class="btn btn-primary float-right btn-label"
                          type="button"
                          @click="updateKitchen"
                          v-show="disabledUpdate && $can('update_kitchen')"
                        >
                          <i class="far fa-save label-icon "></i> Enregistrer
                        </button>
                        
                      </div>
                        
                    </div>
                </fieldset>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      <span class="d-inline-block d-sm-none">
                        <i class="far fa-user"></i>
                      </span>
                      <span class="d-none d-sm-inline-block" @click="tableindex = 1"
                        >Fiche d'inventaire</span>
                    </template>
                    <equipement  :selectedEquipements="selectedEquipements" :selectedkitchen="selectedK"/>
                  </b-tab>

                        
                </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>